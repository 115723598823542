import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as Feather from 'feather-icons';
import { AuthService, BasketService, GlobalService, OrderService, ProductService } from 'src/app/services';

@Component({
    selector: 'quantity',
    templateUrl: './quantity.component.html',
    styleUrls: ['./quantity.component.scss'],
})
export class QuantityComponent implements OnInit, AfterViewInit {

    @Input() item;
    @Input() size;
    relatedItem;
    quantityType: string;
    quantityInPieces: string;

    constructor(
        private alertCtrl: AlertController,
        public globalService: GlobalService,
        public authService: AuthService,
        public basketService: BasketService,
        public productService: ProductService,
        private translateService: TranslateService,
        private orderService: OrderService,
    ) {
    }

    ngOnInit() {
        this.basketService.basket.filter((el) => {
            if (this.authService.store.uuid == this.globalService.companyId) {
                if (el.uuid === this.item.uuid && el.printing_selected === this.item.printing_selected) {
                    this.item.quantity = el.quantity;
                }
            } else {
                if (el.uuid === this.item.uuid) {
                    this.item.quantity = el.quantity;
                }
            }
        });

        this.item.minimum_order_qty = this.item.minimum_order_qty === null ? 0 : +this.item.minimum_order_qty;
        this.orderService.setQuantityTypeLabel(this.item);
        this.quantityType = this.orderService.quantityType;
        this.quantityInPieces = this.orderService.quantityInPieces;
    }

    ngAfterViewInit() {
        Feather.replace();
    }

    async updateQuantity(quantity: number, value: number) {
        if (quantity >= 0) {
            // this.item.quantity = (quantity + value) <= this.item.minimum_order_qty ? this.item.minimum_order_qty : quantity + value;
            this.item.quantity = quantity + value;

            if (this.item.printing_selected == null) {
                this.item.printing_selected = 'Geen';
            }

            if (this.item.set_item_uuid != null) {
                this.item.set_item = this.item.set_item == true ? true : false;
            }
            // if (this.item.quantity >= this.item.minimum_order_qty) {
            this.basketService.updateBasket(this.item);

            this.setRelatedItem();
            // } else {
            //   this.basketService.removeFromBasket(this.item);
            // }

            this.orderService.setQuantityTypeLabel(this.item);
            this.quantityType = this.orderService.quantityType;
            this.quantityInPieces = this.orderService.quantityInPieces;
        }
    }

    async setRelatedItem() {
        if (this.item.set_item == true) {
            this.relatedItem = await this.productService.getRelatedProduct(this.item.set_item_uuid);

            if (this.relatedItem) {
                this.relatedItem.quantity = this.item.quantity;
            }

            if (this.relatedItem.printing_selected == null) {
                this.relatedItem.printing_selected = 'Geen';
            }

            this.basketService.updateBasket(this.relatedItem, true);
        }
    }

    /**
    * Shows a popup for setting the quantity for the specified product.
    */
    private setQuantity() {
        var q = "";
        if (this.item.quantity > 0) {
            q = this.item.quantity.toString();
        }

        this.showPrompt(this.item.name, q, (data) => {
            var qty: number = +data.quantity;
            if (qty >= 0) {
                // if (qty >= this.item.minimum_order_qty) {
                this.item.quantity = +data.quantity;
                this.basketService.updateBasket(this.item);
                this.setRelatedItem();
            }
        });
    }

    public async showPrompt(productName: string, currentValue: string, handler: (data: any) => void) {
        let prompt = await this.alertCtrl.create({
            header: `${this.item.name}`,
            message: this.translateService.instant('BASKET.CUSTOM_QUANTITY_MESSAGE') + `<br> Let op: Min. bestelhoeveelheid ${this.item.minimum_order_qty} stuks`,
            inputs: [
                {
                    name: 'quantity',
                    placeholder: this.translateService.instant('BASKET.QUANTITY'),
                    value: currentValue,
                    type: 'number'
                },
            ],
            buttons: [
                {
                    text: this.translateService.instant('GENERIC.CANCEL'),
                    handler: handler
                },
                {
                    text: this.translateService.instant('GENERIC.DONE'),
                    handler: handler
                }
            ]
        });

        prompt.present().then(() => {
            const firstInput: any = document.querySelector('ion-alert input');
            firstInput.focus();
            return;
        });
    }
}
