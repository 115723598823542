import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { ItemComponent } from './product/item/item.component';
import { QuantityComponent } from './product/quantity/quantity.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';


@NgModule({
	declarations: [
		HeaderComponent,
		ItemComponent,
		QuantityComponent,
		ShowHidePasswordComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
	],
	exports:[
		TranslateModule,
		HeaderComponent,
		ItemComponent,
		QuantityComponent,
		ShowHidePasswordComponent,
	]
})
export class ComponentsModule {}
