import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

const ROUTE = 'CUSTOMER';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    public quantityTypes = [
        {
            id: 'piece',
            name: 'Per stuk',
            label_singular: 'stuk',
            label_plural: 'stuks',
        },
        {
            id: 'pack',
            name: 'Per pak',
            label_singular: 'pak',
            label_plural: 'pakken',
        },
        {
            id: 'pallet',
            name: 'Per pallet',
            label_singular: 'pallet',
            label_plural: 'pallets',
        },
    ];

    constructor(
        public storageService: StorageService
    ) { }

    public getAccount() {
        return this.storageService.get(ROUTE).then(x => x);
    }
}
