import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

Object.defineProperty(Array.prototype, "remove", {
  enumerable: false,

  writable: true,

  value: function (x) {
    var i;

    for (i in this) {
      if (JSON.stringify(this[i]) == JSON.stringify(x)) {
        this.splice(i, 1);
      }
    }
  },
});
