import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() title: string;
  @Input() showOrder: boolean;
  @Input() enableBackBtn: boolean;
  
  constructor(
    private navCtrl: NavController
  ) { }

  ngOnInit() {}

  /**
  * Navigates to previous page.
  */
  back(){
    this.navCtrl.back();
  }
}
