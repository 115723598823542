import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { StorageService } from '../storage/storage.service'

const LANG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = '';

  constructor(
    private translate: TranslateService,
    private storage: StorageService
  ) { }

  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    // this.translate.setDefaultLang(language);
    this.translate.setDefaultLang('nl');

    this.storage.get(LANG_KEY).then(val => {
      if (val) {
        this.setLanguage(val);
        this.selected = val;
      }
    });
  }

  getLanguage() {
    return [
      { text: 'English', value: 'en' },
      { text: 'Dutch', value: 'nl' }
    ]
  }

  setLanguage(lang) {
    this.translate.use(lang);
    this.selected = lang;
    this.storage.set(LANG_KEY, lang);
  }
}
