import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BasketService, OrderService } from 'src/app/services';

@Component({
    selector: 'product-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

    @Input() item: { uuid; ean; name; short_description; description; sku; image; product; printing; printing_selected };
    @Input() type;
    quantityType: string;
    quantityInPieces: string;

    constructor(
        private router: Router,
        private navCtrl: NavController,
        public basketService: BasketService,
        private orderService: OrderService,
    ) { }

    ngOnInit() {
        if (this.type === 'ordered' && this.item) {
            this.item.name = '';
            this.item.description = this.item.description;
            this.item.short_description = '';
            this.item.image = null;

            if (this.item.product !== null) {
                this.item.name = this.item.product.name;
                this.item.description = this.item.product.description;
                this.item.short_description = this.item.product.short_description;
                this.item.image = this.item.product.image;
            }
            this.item.printing_selected = this.item.printing;

            this.orderService.setQuantityTypeLabel(this.item, true);
            this.quantityType = this.orderService.quantityType;
            this.quantityInPieces = this.orderService.quantityInPieces;
        }
    }

    removeItem(item) {
        this.basketService.removeFromBasket(item);
    }

    openDetails(uuid) {
        if (this.type != 'ordered') {
            if (this.type == 'basket') {
                if (this.item.printing_selected == null) {
                    this.item.printing_selected = 'Geen';
                }

                this.navCtrl.navigateForward(`${this.router.routerState.snapshot.url}/product/${uuid}/${this.item.printing_selected}`);
            } else {
                this.navCtrl.navigateForward(`${this.router.routerState.snapshot.url}/product/${uuid}`);
            }
        }
    }
}
