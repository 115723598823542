export const config = {
    appName: 'Wolters',
    // domain: 'http://localhost:8888/tofferbackend-2018',
    // domain: 'http://tofferio.localhost',
    domain: 'https://admin.toffer.io',
    companyId: '228a67f8-da44-11eb-b073-901b0ef305a3',
    companyName: 'Wolters',
    language: 'nl',
    locale: 'nl-NL',
    currency: 'EUR',
    device: true,
    darkMode: false
};
