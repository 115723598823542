import { Injectable, NgZone } from '@angular/core';

import { Capacitor, } from '@capacitor/core';
import { App } from '@capacitor/app';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { NavController } from '@ionic/angular';

import { StorageService } from '../storage/storage.service';
import { GlobalService } from '../global/global.service';
import { ApiService } from '../api/api.service';
import { AccountService } from '../account/account.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    public notificationsList = [];
    public receivedNotificationslist = [];
    public notificationsCount: number;

    constructor(
        private navCtrl: NavController,
        public zone: NgZone,
        public apiService: ApiService,
        public storageService: StorageService,
        public globalvar: GlobalService,
        public accountService: AccountService,
    ) { }

    initPush() {
        if (this.globalvar.expoConfig) {
            Object.keys(this.globalvar.expoConfig).forEach((key) => {
                // in order to have the push key once it is available
                if (key === 'deviceToken' && this.globalvar.expoConfig.deviceToken !== null) {
                    this.updatePushToken();
                }
            });
        }
    }

    updatePushToken() {
        this.globalvar.phonePushPermission = this.globalvar.expoConfig.pushEnabled;

        const data = {
            push_key: this.globalvar.expoConfig.deviceToken,
            platform: this.globalvar.expoConfig.deviceType ?? Capacitor.getPlatform(),
            has_permission: this.globalvar.phonePushPermission ? '1' : '0',
        };

        return this.apiService.post(`refresh-push-token`, this.globalvar.objectToFormData(data)).then((response: any) => response, (err) => {
            console.error(err);
        });
    };

    notificationReceived(entireNotification) {
        if (entireNotification.actionId === 'tap') {
            const notification = entireNotification.notification?.data;
            const type = notification?.type ?? notification?.['gcm.notification.type'];

            this.checkType(type);
        }
    }

    checkType(type) {
        switch (type) {
            case 'chatmessage':
                this.zone.run(() => {
                    this.navCtrl.navigateRoot([`tabs/chats`], { replaceUrl: true });
                });
                break;
            default:
                this.zone.run(() => {
                    this.navCtrl.navigateRoot([`tabs/`], { replaceUrl: true });
                });
                break;
        }
    }
}
