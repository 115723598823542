import { Injectable } from '@angular/core';
import { GlobalService, StorageService, ApiService, AuthService, ProductService } from 'src/app/services';

const KEY = 'BASKET';

@Injectable({
    providedIn: 'root'
})
export class BasketService {

    public basket: any = [];
    private details: any = [];

    constructor(
        public globalService: GlobalService,
        public storageService: StorageService,
        public apiService: ApiService,
        public authService: AuthService,
        public productService: ProductService,
    ) { }

    /**
     * Set or Get Basket Data.
     */
    public basketData() {
        if (this.basket.length) {
            this.basket = this.basket.filter(async (item) => {
                if (item.quantity !== 0 || item.favorite !== false) {

                    item = this.updateRelatedItem(item) ?? item;

                    return item;
                }
                if (item.quantity <= 0) {
                    this.removeFromBasket(item);
                }
            });

            this.saveBasket();
        } else {
            this.getBasket();
        }
    }

    /**
     * Get all items from basket.
     */
    public getBasket() {
        this.storageService.get(KEY).then((storageData) => {
            if (storageData) { this.basket = storageData; }
        });
    }

    /**
     * Update basket by product item.
     */
    public updateBasket(item, related?) {
        // Necessary for Wolters
        if (this.authService.store.uuid === this.globalService.companyId && item.printing != null) {
            if (related === true) {
                item = this.setRelatedItemQuantity(item);
            }

            if (!this.basket.some((el) => el.uuid === item.uuid && el.printing_selected === item.printing_selected)) {
                this.basket.push(item);
            } else {
                this.basket.filter((el) => {
                    if (el.uuid === item.uuid && el.printing_selected === item.printing_selected) {
                        el.quantity = item.quantity;
                        el.printing_selected = item.printing_selected;

                        if (el.set_item_uuid != null) {
                            el.set_item = item.set_item;
                        }
                    }
                });
            }
        } else {
            // if (!this.basket.some((el) => el.uuid === item.uuid)) {
            //   this.basket.push(item);
            // } else {
            //   this.basket.filter((el) => {
            //     if (el.uuid === item.uuid) {
            //       el.quantity = item.quantity;

            //       if (el.set_item_uuid != null) {
            //         el.set_item = item.set_item ?? null;
            //       }
            //     }
            //   });
            // }
        }

        // this.badgeCount();
        // this.totalPriceExcl();
    }

    /**
     * Removes all items from basket.
     */
    public clearBasket() {
        this.basket = this.basket.filter(x => false);
        this.saveBasket();
    }

    /**
     * Removes item from basket.
     */
    public removeFromBasket(item) {
        this.basket.forEach(el => {
            if (el.set_item_uuid === item.uuid) {
                el.set_item = false;
            }
        });
        this.updateRelatedItem(item);

        this.basket.remove(item);


        this.saveBasket();
    }


    public updateFavoriteList(item) {
        if (!this.basket.some((el) => el.uuid === item.uuid && el.printing_selected === item.printing_selected)) {
            this.basket.push(item);
        }
    }

    // public badgeCount() {
    //   let _badgeCount: number = 0;
    //   this.basket.forEach((item) => {
    //     _badgeCount += item.quantity;
    //   });
    //   this.listSizeSubject.next(_badgeCount);
    // }

    // public totalPriceExcl() {
    //   let itemsPrices: number = 0;
    //   let basketTotalPriceExcl: any;

    //   this.basket.forEach((item) => {
    //     itemsPrices += (item.Details[0].SalesPrice * item.Quantity);
    //   }), err => console.error(err);

    //   basketTotalPriceExcl = itemsPrices.toFixed(2);
    //   this.priceExclSubject.next(basketTotalPriceExcl);
    // }

    // public setDetails(id, details) {
    //   this.details[id] = details;
    // }

    // public getDetails(id) {
    //   return this.details[id];
    // }

    public setBasketItem(item: any) {
        return this.basket.filter((el) => {
            if (el.uuid === item.uuid && el.printing_selected === item.printing_selected) {
                el.quantity = item.quantity;

                if (el.set_item_uuid != null) {
                    el.set_item = item.set_item ?? null;
                }
                return true;
            } else if (el.uuid === item.uuid) {
                el.quantity = item.quantity;
                return false;
            }
        });
    }

    public setRelatedItemQuantity(item) {
        let qty = 0;
        this.basket.forEach((el) => {
            if (el.set_item_uuid === item.uuid) {
                if (el.set_item === true) {
                    qty += el.quantity;
                }
            }
        });

        item.quantity = qty;

        if (item.printing_selected == null) {
            item.printing_selected = 'Geen';
        }

        return item;
    }

    public async updateRelatedItem(item) {
        if (item.set_item === true) {
            let relatedItem = await this.productService.getRelatedProduct(item.set_item_uuid);
            relatedItem = this.setRelatedItemQuantity(relatedItem);
            this.updateBasket(relatedItem);
        }
        return item;
    }

    public getItemInfo(item, isOrderLine = false) {
        const itemInfo = {
            product_uuid: item.uuid,
            quantity: null,
            quantity_type: null,
            quantity_in_pieces: null,
            quantity_in_pack: null,
            quantity_on_pallet: null,
            price_inc_vat: '0',
            price_ex_vat: '0',
            printing: '',
        };
        if (item.quantity !== 0) {
            let quantityInType = 1;

            switch (this.authService.user.preferred_quantity_type) {
                case 'pack':
                    quantityInType = parseInt(item.qty_in_pack, 10) ?? 1;
                    break;

                case 'pallet':
                    quantityInType = parseInt(item.qty_on_pallet, 10) ?? 1;
                    break;
            }

            itemInfo.quantity = item.quantity;
            itemInfo.quantity_type = this.authService.user.preferred_quantity_type;
            itemInfo.quantity_in_pieces = item.quantity * quantityInType;
            itemInfo.quantity_in_pack = parseInt(item.qty_in_pack ?? item.quantity_in_pack, 10);
            itemInfo.quantity_on_pallet = parseInt(item.qty_on_pallet ?? item.quantity_on_pallet, 10);
        }

        if (isOrderLine) {
            itemInfo.quantity = item.quantity;
            itemInfo.quantity_type = item.quantity_type;
            itemInfo.quantity_in_pieces = parseInt(item.quantity_in_pieces, 10);
            itemInfo.quantity_in_pack = parseInt(item.quantity_in_pack, 10);
            itemInfo.quantity_on_pallet = parseInt(item.quantity_on_pallet, 10);
        }

        return itemInfo;
    }

    /**
     * Save basket.
     */
    private saveBasket() {
        this.storageService.set(KEY, this.basket);
    }
}
