import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { AccountService, GlobalService, StorageService } from 'src/app/services';
import { BehaviorSubject } from 'rxjs';

export class Credentials {
    email: string;
    password: string;

    constructor(email?: string, password?: string) {
        this.email = email;
        this.password = password;
    }
}

const TOKEN_KEY = 'TOKEN';
const CUSTOMER_KEY = 'CUSTOMER';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authState = new BehaviorSubject(false);
    isLoggedIn = false;
    token: any;
    user: any;
    store: any;

    constructor(
        private navCtrl: NavController,
        private http: HttpClient,
        public globalService: GlobalService,
        public storageService: StorageService,
        public accountService: AccountService
    ) { }

    login(credentials?: Credentials) {
        const body = {
            store_uuid: this.globalService.companyId,
            username: credentials.email,
            password: credentials.password,
        };
        const data = this.globalService.objectToFormData(body);

        return this.http
            .post(`${this.globalService.domain}/api/login`, data, {
                headers: new HttpHeaders()
            })
            .pipe(tap((token) => {
                this.storageService.set(TOKEN_KEY, token).then(() => {
                    this.globalService.authToken = token;
                    this.token = this.globalService.authToken;
                    this.user = this.globalService.authToken.user;

                    this.storageService.set(CUSTOMER_KEY, this.globalService.authToken.user).then(res => {
                        this.getToken();
                        this.getUser();
                    });

                    return token;
                },
                    (error) => {
                        console.error('Fout bij het opslaan van het token', error);
                    });
            }));
    }

    logout() {
        this.storageService.remove(TOKEN_KEY).then(() => {
            this.storageService.remove(CUSTOMER_KEY);
            this.navCtrl.navigateRoot(['/'], { replaceUrl: true });
            this.authState.next(false);
        });
    }

    async getHeaderToken(): Promise<string> {
        return await this.storageService.get(TOKEN_KEY) ?? '';
    }

    getToken() {
        return this.storageService.get(TOKEN_KEY).then((data) => {
            this.token = data;

            if (this.token != null) {
                this.globalService.authToken = this.token;
                this.store = this.token.store_config;
                if (this.token.user.is_active == 0) {
                    this.isLoggedIn = false;
                    this.authState.next(false);
                } else {
                    this.isLoggedIn = true;
                    this.authState.next(true);
                }
            } else {
                this.isLoggedIn = false;
                this.authState.next(false);
            }
        },
            (error) => {
                this.isLoggedIn = false;
                this.authState.next(false);
            }
        );
    }
    getUser() {
        return this.storageService.get(CUSTOMER_KEY).then((data) => {
            this.user = data;

            const quantityType = this.accountService.quantityTypes.filter((type) => type.id === this.user?.preferred_quantity_type);
            if (quantityType.length > 0) {
                this.globalService.preferredOrderQuantity = quantityType[0].name;
            }

            this.globalService.userAllowsPush = this.user?.allows_pushnotifications;
        }, (error) => {
            console.error('empty customer', error);
        });
    }

    isAuthenticated() {
        return this.authState.value;
    }
}
