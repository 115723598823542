import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    public globalService: GlobalService
  ) { }

  async set(key: string, value: any) {
    await Preferences.set({
      key: this.globalService.storageUserKey + '_' + key,
      value: JSON.stringify(value)
    });
  }

  async get(key: string) {
    const item = await Preferences.get({ key: this.globalService.storageUserKey + '_' + key });
    return JSON.parse(item.value);
  }

  async remove(key: string) {
    await Preferences.remove({ key: this.globalService.storageUserKey + '_' + key });
  }

  async clear() {
	  await Preferences.clear();
	}
}
