import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService, GlobalService, StorageService } from 'src/app/services';
import { Subscriber } from 'rxjs';
import 'rxjs/add/operator/share';

type Token = {
    token: string;
    store_config: string[];
    user: string[];
};

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    url = '';
    storeId = '';
    private token: string;


    constructor(
        public globalService: GlobalService,
        public storageService: StorageService,
        public login: AuthService,
        public http: HttpClient,
        public zone: NgZone,
    ) {
        this.url = this.globalService.domain;
        this.storeId = this.globalService.companyId;
    }

    async get(endpoint: string, params?: any, reqOpts?: any): Promise<Subscriber<any>> {
        const token = await this.authToken();

        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
                headers: new HttpHeaders({'X-Authorization': token}),
            };
        }

        // Support easy query params for GET requests
        if (params) {
            reqOpts.params = new HttpParams();
            for (const k in params) {
                reqOpts.params = reqOpts.params.set(k, params[k]);
            }
        }

        const request = this.http.get(this.initUrl(endpoint), reqOpts);

        const seq = request.share();
        return new Promise((resolve, reject) => {
            seq.subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }

    async post(endpoint: string, body: any, params?: any, reqOpts?: any, appendStoreId = true) {
        const token = await this.authToken();

        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
                headers: new HttpHeaders({'X-Authorization': token}),
            };
        }

        // Support easy query params for GET requests
        if (params) {
            reqOpts.params = new HttpParams();
            for (const k in params) {
                reqOpts.params = reqOpts.params.set(k, params[k]);
            }
        }

        const request = this.http.post(this.initUrl(endpoint, appendStoreId), body, reqOpts);
        const seq = request.share();
        return new Promise((resolve, reject) => {
            seq.subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }

    async put(endpoint: string, body: any, params?: any, reqOpts?: any, appendStoreId = true) {
        const token = await this.authToken();

        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
                headers: new HttpHeaders({
                    'X-Authorization': token,
                }),
            };
        }

        // Support easy query params for GET requests
        if (params) {
            reqOpts.params = new HttpParams();
            for (const k in params) {
                reqOpts.params = reqOpts.params.set(k, params[k]);
            }
        }

        const request = this.http.put(this.initUrl(endpoint, appendStoreId), body, reqOpts);
        const seq = request.share();
        return new Promise((resolve, reject) => {
            seq.subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }

    delete(endpoint: string, reqOpts?: any) {
        return this.http.delete(this.initUrl(endpoint), reqOpts);
    }

    patch(endpoint: string, body: any, reqOpts?: any) {
        return this.http.patch(this.initUrl(endpoint), body, reqOpts);
    }

    fetchUser() {
        this.get('/person').then(result => {
            this.globalService.authToken.user = result;
            this.storageService.set('CUSTOMER', this.globalService.authToken.user).then(res => {
                this.login.getUser();
            });
        }).catch((err) => {
            console.error('ERROR', err);
        });
    }

    private authToken() {
        return this.login.getHeaderToken().then((value: any) => {
            this.token = value.token;
            if (typeof this.token !== 'undefined' && this.token.length === 0) {
                return null;
            };

            return this.token;
        });
    }

    private initUrl(route?: string, appendStoreId = true): string {
        if (route && appendStoreId) {
            return `${this.url}/api/store/${this.storeId}/` + route;
        } else if (route && !appendStoreId) {
            return `${this.url}/api/` + route;
        } else {
            return `${this.url}/api/store/${this.storeId}/`;
        }
    }
}
