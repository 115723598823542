import { Injectable } from '@angular/core';
import { GlobalService, ApiService, BasketService, AuthService, AccountService, ProductService } from 'src/app/services';

const ROUTE = 'orders';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    public orders;
    public quantityType: string;
    public quantityInPieces: string;

    constructor(
        public globalService: GlobalService,
        public apiService: ApiService,
        public authService: AuthService,
        public basketService: BasketService,
        public accountService: AccountService,
        public productService: ProductService,
    ) { }

    public submitOrder(userID, remarks, deliveryAddressUuid, requestedDeliveryDate) {
        const orderLines = [];
        const addressList = [];

        this.basketService.basket.filter((item) => {
            if (item.quantity !== 0) {
                const itemInfo = this.basketService.getItemInfo(item);

                if (itemInfo?.quantity_in_pieces < parseInt(item.minimum_order_qty, 10)) {
                    return false;
                }

                if (item.printing) {
                    itemInfo.printing = item.printing;
                }

                orderLines.push(itemInfo);
            }
        });

        const deliveryAddresses = this.authService.user.contact_details.addresses;
        let deliveryAddress;
        deliveryAddresses.forEach(address => {
            if (address.uuid === deliveryAddressUuid) {
                deliveryAddress = address;
            }
        });

        const deliveryInfo = {
            address_type: `${deliveryAddress.type.name.toLowerCase()}`,
            city: `${deliveryAddress.city}`,
            country: `${deliveryAddress.country}`,
            number: `${deliveryAddress.number}`,
            zip_code: `${deliveryAddress.zip_code}`,
            street: `${deliveryAddress.street}`,
        };
        addressList.push(deliveryInfo);

        const body = {
            store_uuid: this.globalService.companyId,
            customer_uuid: userID,
            payment_method_uuid: '46f88e45-dce6-11eb-b073-901b0ef305a3',
            delivery_method_uuid: '54914898-dce6-11eb-b073-901b0ef305a3',
            addresses: addressList,
            remarks,
            requested_delivery_date: requestedDeliveryDate ? requestedDeliveryDate + 'T00:00:00' : '',
            orderlines: orderLines,
        };

        const data = this.globalService.objectToFormData(body);

        return new Promise((resolve, reject) => {
            this.apiService.post(ROUTE, data, null, null, false).then(result => {
                this.basketService.clearBasket();
                //Removed products from Storage & redirect to shop
                resolve(result);
            }).catch((err) => {
                reject(err);
                console.error('ERROR', err);
            });
        });
    }

    public getOrders() {
        return new Promise((resolve, reject) => {
            this.apiService.get(ROUTE).then(result => {
                resolve(result);
            }).catch((err) => {
                reject(err);
                console.error('ERROR', err);
            });
        });
    }

    public setQuantityTypeLabel(item, isOrderLine = false) {
        const quantityType = this.accountService.quantityTypes.filter((type) => type.id === this.authService.user.preferred_quantity_type);
        if (quantityType.length > 0) {
            if (item.quantity === 1) {
                this.quantityType = quantityType[0].label_singular;
            } else {
                this.quantityType = quantityType[0].label_plural;
            }
        }

        this.setQuantityInPieces(item, isOrderLine);
    }

    public setQuantityInPieces(item, isOrderLine = false) {
        const quantityType = this.accountService.quantityTypes.filter((type) => type.id === 'piece');
        const itemInfo = this.basketService.getItemInfo(item, isOrderLine);

        if (itemInfo.quantity_in_pieces > 0 && quantityType.length > 0) {
            this.quantityInPieces = itemInfo.quantity_in_pieces;
            if (itemInfo.quantity_in_pieces === 1) {
                this.quantityInPieces += ' ' + quantityType[0].label_singular;
            } else {
                this.quantityInPieces += ' ' + quantityType[0].label_plural;
            }

            this.quantityInPieces = ` (${this.quantityInPieces})`;
        }
    }

    public async submitNotOrderToday(userID, deliveryAddressUuid) {
        const orderLines = [];
        const addressList = [];

        await this.productService.getProducts(this.authService.user.category_uuid).then(res => {
            if (Array.isArray(res)) {
                res.forEach(item => {
                    if (item.sku === this.globalService.willNotOrderTodayItemNo) {
                        item.quantity = 1;
                        item.printing = 'Geen';
                        const itemInfo = this.basketService.getItemInfo(item);
                        orderLines.push(itemInfo);
                    }
                });
            }
        }, err => {
            console.error(err);
        });

        const deliveryAddresses = this.authService.user.contact_details.addresses;
        let deliveryAddress;
        deliveryAddresses.forEach(address => {
            if (address.uuid === deliveryAddressUuid) {
                deliveryAddress = address;
            }
        });

        const deliveryInfo = {
            address_type: `${deliveryAddress.type.name.toLowerCase()}`,
            city: `${deliveryAddress.city}`,
            country: `${deliveryAddress.country}`,
            number: `${deliveryAddress.number}`,
            zip_code: `${deliveryAddress.zip_code}`,
            street: `${deliveryAddress.street}`,
        };
        addressList.push(deliveryInfo);

        const body = {
            store_uuid: this.globalService.companyId,
            customer_uuid: userID,
            payment_method_uuid: '46f88e45-dce6-11eb-b073-901b0ef305a3',
            delivery_method_uuid: '54914898-dce6-11eb-b073-901b0ef305a3',
            addresses: addressList,
            remarks: '',
            requested_delivery_date: '',
            orderlines: orderLines,
        };

        const data = this.globalService.objectToFormData(body);

        return new Promise((resolve, reject) => {
            this.apiService.post(ROUTE, data, null, null, false).then(result => {
                resolve(result);
            }).catch((err) => {
                reject(err);
            });
        });
    }
}
