import { Injectable } from '@angular/core';

import { ApiService } from 'src/app/services';

const ROUTE = 'products';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private category;

  constructor(public apiService: ApiService) { }

  getProducts(categoryUuid: any) {
    return new Promise((resolve, reject) => {
      this.apiService.get(`categories/${categoryUuid}/${ROUTE}`).then(result => {
        resolve(result);
      }).catch((err) => {
        reject(err);
        console.error('ERROR', err);
      });
    });
  }

  getProduct(productUuid: any) {
    return new Promise((resolve, reject) => {
      this.apiService.get(`${ROUTE}/${productUuid}`).then(result => {
        resolve(result);
      }).catch((err) => {
        reject(err);
        console.error('ERROR', err);
      });
    });
  }

  async getRelatedProduct(relatedItemUuid) {
    return new Promise((resolve, reject) => {
      this.getProduct(relatedItemUuid).then((res) => {
        resolve(res);
      },
        err => {
          reject(err);
        });
    });
  }
}
