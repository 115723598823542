import { Injectable } from "@angular/core";

import { ApiService, StorageService } from 'src/app/services';

const ROUTE = 'categories';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    public apiService: ApiService,
    public storageService: StorageService
  ) { }

  async getCategories() {
    return await this.apiService.get(ROUTE).then(result => {
      return result;
    }).catch((err) => {
      console.error('ERROR', err);
      return err;
    });
  }

  async getCategory(uuid: string) {
    let categoryList: any = await this.getCategories();
    return uuid ? await categoryList.find(cat => cat.uuid == uuid) : '';
  }
}
