import { Injectable } from '@angular/core';
import { config } from 'src/assets/config/config';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    public deploy = '0.0.2';
    public authToken;
    public version: string;

    public language: string;
    public locale: string;
    public currency: string;
    public device: boolean;
    public preferredOrderQuantity: string;

    public domain: string = config.domain;
    public companyId: string = config.companyId;
    public companyName: string = config.appName;

    // Storage keys
    public storageUserKey = `${this.companyName}_user`;

    public phonePushPermission: boolean;
    public userAllowsPush: boolean;

    public willNotOrderTodayItemNo = '9990000';

    public expoConfig = {
        deviceToken: null,
        pushEnabled: null,
        deviceType: null,
    };

    constructor() { }

    objectToFormData(model: any, form: FormData = null, namespace = ''): FormData {
        const formData = form || new FormData();

        for (const propertyName in model) {
            if (!model.hasOwnProperty(propertyName) || !model[propertyName]) { continue; }
            const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
            if (model[propertyName] instanceof Date) { formData.append(formKey, model[propertyName].toISOString()); }
            else if (model[propertyName] instanceof Array) {
                model[propertyName].forEach((element, index) => {
                    const tempFormKey = `${formKey}[${index}]`;
                    this.objectToFormData(element, formData, tempFormKey);
                });
            }
            else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
                this.objectToFormData(model[propertyName], formData, formKey);
            }
            else { formData.append(formKey, model[propertyName].toString()); }
        }
        return formData;
    }
}
